<template>
  <b-container>
    <b-row class="contact-ctn">
      <b-col>
        <p>¿Quieres montar una fiesta?<br> ¿Necesitas DJ o equipo de sonido?</p>
        <p> Te ayudamos </p>
        <p> <b-icon-telephone-fill></b-icon-telephone-fill> <span class="ml-2">665 20 50 03</span> </p>
        <p> <b-icon-chat-text-fill></b-icon-chat-text-fill> <span class="ml-2">juanantonioruizaragon@gmail.com</span> </p>
      </b-col>
      <b-col class="text-center">
        <h4 class="mb-4">¡Síguenos!</h4>
        <p>
          <a class="mr-3" href="https://www.facebook.com/partyrevive.eventos"><b-icon-facebook></b-icon-facebook> <span class="ml-2">PARTY REVIVE</span></a>
          <a class="mr-3 mt-2" href="https://www.instagram.com/party_revive/"><b-icon-instagram></b-icon-instagram> <span class="ml-2">PARTY REVIVE</span></a>
          <a class="mr-3 mt-2" href="https://www.instagram.com/djpuerro/"><b-icon-instagram></b-icon-instagram> <span class="ml-2">DJ PUERRO</span></a>
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '../assets/scss/global.scss';
  .contact-ctn {
    background-color: #00000088;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    border: 1px solid $red;
    border-radius: 20px;
     @include desktop {
      padding: 3rem 5rem;
      margin-top: 5rem;
    }

    @include mobile {
      padding: 2rem 1rem;
    }
  }
  p, h4{
    color: white;
  }

  a {
    color: white;
    display: block;
    &:hover {
      transition: .5s all ease-in;
      color: $red;
    }
    font-size: 1.1rem;
  }
</style>